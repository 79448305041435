//iOSAppsSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { fetchInitialiOSApps, fetchiOSAppById, fetchProductInsightForiOSApp, fetchIOSCompetitorsByAppId } from '../thunks/iOSAppsThunk'
import { fetchIOSUserFlowById } from '../thunks/userFlowThunk'
import { logout } from './UserAuthSlice'
import { resetOtherStateAfterLogin } from './UserAuthSlice'
import {filteredApps } from '../thunks/webAppsThunk'

const initialState = {
	initialiOSApps:[],
	filteredApps: [],
	hasMore: false,
	limit: 10,
	selectediOSApp: {}, 
	productInsight: {},

}

const iOSAppsSlice = createSlice({
	name:"iosapps",
	initialState,
	reducers: {
		selectediOSApp: (state, action)=> {
			state.selectediOSApp = action.payload
		}
	},
	extraReducers(builder){
		builder.addCase(fetchInitialiOSApps.fulfilled, (state, action) => {
       //console.log(action.payload.data.iosapps)
      state.initialiOSApps = action.payload.data.iosapps //updating the webapps
    });
		builder.addCase(fetchiOSAppById.fulfilled, (state, action) => {
        //console.log(action.payload.data.appById)
       state.selectediOSApp = action.payload.data.appById //updating the selectediOSApp
	   state.productInsight = {
		...state.productInsight,
		...action.payload.data.productInsightAppById
	  }
	   
    });
    builder.addCase(fetchIOSUserFlowById.fulfilled, (state, action) => {
       // console.log("iOSAppsSlice  fetchIOSUserFlowById", action.payload.data)
       state.selectediOSApp = action.payload.data.userFlowId.app //updating the selectedWebApp
    });
    builder.addCase(fetchProductInsightForiOSApp.fulfilled, (state, action) => {
       // console.log("iOSAppsSlice  fetchProductInsightForiOSApp", action.payload.data)
	  state.productInsight = {
		...state.productInsight,
		...action.payload.data.productInsightAppById
	  }
    });	
	builder.addCase(logout, (state, action) => {
		//console.log("logout")
		return initialState
	})
	builder.addCase(resetOtherStateAfterLogin, (state, action) => {
		//console.log("resetOtherStateAfterLogin")
		return initialState
	})
	builder.addCase(fetchIOSCompetitorsByAppId.fulfilled, (state, action) => {
		//console.log("fetchIOSCompetitorsByAppId", action.payload.data.findCompetitorsByAppId)
		const competitors = action.payload.data.findCompetitorsByAppId
		state.productInsight = {
			...state.productInsight,
			competitors
		}
	})
	builder.addCase(filteredApps.fulfilled, (state, action) => {
		//console.log("filteredApps in iOSAppsSlice", action.payload.data.filteredApps)
		const apps  = action.payload.data.filteredApps
		const iosapps = apps.filter(app => app.__typename === "iOS")
		//console.log("filtered iOSapps", iosapps)
		state.filteredApps = iosapps

	})
	
}
})


export const { selectediOSApp } = iOSAppsSlice.actions;
export const iOSappsReducer = iOSAppsSlice.reducer;