// FlowsGridImageRowiOSApp;.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {updateSelectedIOSFlow} from '../../store'
import { useNavigate } from 'react-router-dom';





const FlowsGridImageRowiOSApp = ({screens, flowId, flow}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

  //console.log(screens)
  //console.log(flowId)
  //console.log(flow)

const handleClickButton = () => {
    if (flow.isBlurred) {
        navigate('/signin');
    } else {
        dispatch(updateSelectedIOSFlow(flow))
        navigate(`/ios/apps/flows/${flow.id}`);
    }
   //dispatch(updateSelectedIOSFlow(flow))
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu from opening
  };

  return (
    //console.log(flow.isBlurred),
     <div className={`iosapp-flows-horizontal-scrollable-list-container ${flow.isBlurred ? 'blurred' : ''}`}>
    {/* <Link to={"/ios/apps/flows/"+flowId}
    onClick={()=> handleClickButton(flowId)}
    > */}
    <div onClick={()=> handleClickButton(flow)} style={{ cursor: 'pointer' }}>
    
   <ul className="ios-list">
  
   {
      screens.map((screen, i) => (

            <li key={i} onContextMenu={handleContextMenu}>
                <img src={screen.image.image_url}/>
            </li>
            
          ))
    }
   </ul> 
    
   </div>
  </div> 
  );
};


export default FlowsGridImageRowiOSApp;







