// FlowsGridRowiOSApp;.js
import React from 'react';
import FlowsGridImageRowiOSApp from './FlowsGridImageRowiOSApp'
import { Link } from 'react-router-dom'
import { isProduction } from '../../utils/environment';





const FlowsGridRowiOSApp = ({userFlowRows}) => {
  //console.log(userFlowRows)
  return (
  //asdas
   <div >
   {
      userFlowRows.map((flow, i) => {
        // if(flow.screens.count === 0){
        //    // return <div>{flow.screens.count}</div> 
        //     return null
        // }
        return (
                <div className="screens-flows-rows" key={i}>
                      <h3 className="ui header">
                                     {flow.name}
                                  {// show the screen count text only if the screen count is greator than 0
                                    flow.screens.count > 0 && 
                                      <div className="sub header">
                                        {flow.screens.count > 1 ? `${flow.screens.count} screens` : `${flow.screens.count} screen`}
                                         {flow.totalUserInteractions === 1 ? ' | 1 User Interaction' : flow.totalUserInteractions > 1 ? ` | ${flow.totalUserInteractions} User Interactions` : null}
                                      </div>
                                    }
                                     <p>{isProduction ? null: "userflow.id: "+flow.id}</p>
                       </h3> 
                        <p dangerouslySetInnerHTML={{ __html: flow.description }} />

                                    {// show the image row only if the screen count is greator than 0
                                    flow.screens.count > 0 && 
                                       <FlowsGridImageRowiOSApp screens = {flow.screens.screen} flowId={flow.id} flow={flow}/>
                                    }
                             
                            
                            
                      
              </div>
          )})
    }

   
  </div> 
  );
};


export default FlowsGridRowiOSApp;

//<FlowsGridImageRowiOSApp screens = {flow.screens.screen} flowId={flow.id}/>
