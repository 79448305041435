import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const fetchUserFlowByAppId = createAsyncThunk('fetchUserFlowByAppId/fetch', async(term) =>{
//console.log("fetchUserFlowByAppId thunk ", term)
 // console.log(KEYS.WEBAPPS_PUBLISH_STATUS)
	return fetch(KEYS.GRAPHQL_URL, {
	//const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
          appId: term.idfromURL,
          limit: term.limit,
          skip: term.skip,
          publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
    	query: `
              query ($appId: ID!, $limit: Int, $skip: Int, $publishStatus: String!) {
              userFlowByAppId (appId: $appId, limit: $limit, skip: $skip, publishStatus: $publishStatus) {
                        count 
                        hasMore
                        userflow {
                          id
                          name
                          description
                          totalUserInteractions
                          isBlurred
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              image {
                                  id
                                  image_url
                                }
                            }
                          }
                        } 
                    }
            }
            `	
    })
  }).then(res => res.json())
   // console.log(response)
})


const fetchiOSUserFlowByAppId = createAsyncThunk('fetchiOSUserFlowByAppId/fetch', async(term) =>{
//console.log("fetchiOSUserFlowByAppId thunk ", term)
 // console.log(KEYS.WEBAPPS_PUBLISH_STATUS)
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
          appId: term.idfromURL,
          limit: term.limit,
          skip: term.skip,
          publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
      query: `
              query ($appId: ID!, $limit: Int, $skip: Int, $publishStatus: String!) {
              userFlowByAppId (appId: $appId, limit: $limit, skip: $skip, publishStatus: $publishStatus) {
                        count 
                        hasMore
                        userflow {
                          id
                          name
                          description
                          totalUserInteractions
                          isBlurred
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              image {
                                  id
                                  image_url
                                }
                            }
                          }
                        } 
                    }
            }
            ` 
    })
  }).then(res => res.json())
   // console.log(response)
})


const fetchAllUserFlowNamesByAppId = createAsyncThunk('fetchAllUserFlowNamesByAppId/fetch', async(term) =>{
//console.log("fetchAllUserFlowNamesByAppId thunk ", term)
 // console.log(KEYS.WEBAPPS_PUBLISH_STATUS)
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
          appId: term,
          publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
      query: `
              query ($appId: ID!, $limit: Int, $skip: Int, $publishStatus: String!) {
              userFlowByAppId (appId: $appId, limit: $limit, skip: $skip, publishStatus: $publishStatus) {
                        userflow {
                          id
                          name
                          position
                          description
                          totalUserInteractions
                          isBlurred
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              image {
                                  id
                                  image_url
                                }
                            }
                            
                          }
                        } 
                    }
            }
            ` 
    })
  }).then(res => res.json())
   //console.log(response)
})



const fetchUserFlowById = createAsyncThunk('fetchUserFlowById/fetch', async(term) =>{
  //console.log("fetchUserFlowById thunk ", term)

  const token = localStorage.getItem('token');  // Retrieve the authentication token

  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json",
      'token': token ? `${token}` : '',  // Include the token in the header
     },
    body: JSON.stringify({
      //query: query,
      variables: {
        flowId: term,
        publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
    },
      query: `query UserFlowId($flowId: ID, $publishStatus: String) {
              userFlowId(flowId: $flowId, publishStatus: $publishStatus) {
                          id
                          name
                          description
                          totalUserInteractions
                          isBlurred
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              image {
                                  id
                                  image_url
                                }
                            }
                          }
                         app {
                           ... on Web {
                            name
                            id
                            description
                            logo_url
                            initial_image_url
                            isBlurred
                            categories {
                              id
                              name
                            }
                           }
                         } 
                        } 
                    
            }
            ` 
    })
  }).then(res => res.json())
   // console.log(response)
})


const fetchIOSUserFlowById = createAsyncThunk('fetchIOSUserFlowById/fetch', async(term) =>{

  const token = localStorage.getItem('token');  // Retrieve the authentication token

  //console.log("fetchIOSUserFlowById thunk ", term)
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json",
      'token': token ? `${token}` : '',  // Include the token in the header
     },
    body: JSON.stringify({
      //query: query,
      variables: {
        flowId: term,
        publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
    },
     query: `query UserFlowId($flowId: ID, $publishStatus: String) {
              userFlowId(flowId: $flowId, publishStatus: $publishStatus) {
                          id
                          name
                          description
                          totalUserInteractions
                          isBlurred
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              image {
                                  id
                                  image_url
                                }
                            }
                          }
                         app {
                           ... on iOS {
                            name
                            id
                            description
                            logo_url
                            initial_image_url
                            isBlurred
                            categories {
                              id
                              name
                            } 
                           }
                         } 
                        } 
                    
            }
            ` 
    })
  }).then(res => res.json())
   // console.log(response)
})

const fetchTopAndBottomFlowById = createAsyncThunk('fetchTopAndBottomFlowById/fetch', async({topflowid, bottomflowid}) =>{
  // console.log("fetchTopAndBottomFlowById thunk ")
  // console.log("topflowid", topflowid)
  // console.log("bottomflowid", bottomflowid)


  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json"},
    body: JSON.stringify({
      //query: query,
      variables: {
          topflowid: topflowid,
          bottomflowid: bottomflowid
      },
      query: `query fetchTopAndBottomFlowById($topflowid: ID, $bottomflowid: ID) {
            top: userFlowId (flowId: $topflowid) { 
                          id
                          name
                          totalUserInteractions
                          description
                          isBlurred
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              image {
                                  id
                                  image_url
                                }
                            }
                          }
                         app {
                           ... on Web {
                            __typename
                            name
                            id
                            logo_url
                           }
                            ... on iOS {
                            __typename
                            name
                            id
                            logo_url
                           }
                         } 
                        } 

            bottom: userFlowId (flowId: $bottomflowid) { 
                          id
                          name
                          totalUserInteractions
                          description
                          isBlurred
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              image {
                                  id
                                  image_url
                                }
                            }
                          }
                         app {
                           ... on Web {
                           __typename
                            name
                            id
                            logo_url
                           }
                            ... on iOS {
                            __typename
                            name
                            id
                            logo_url
                           }
                         } 
                        }             
                    
            }
            ` 
    })
  }).then(res => res.json())
   // console.log(response)
})

//thunk for searchUserflowByName
const searchUserflowByName = createAsyncThunk('searchUserflowByName/fetch', async({appId, flowName}) =>{
  //console.log("searchUserflowByName thunk ", appId, flowName);
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      variables: {
          flowName: flowName,
          appId: appId,
      },
      query: `query SearchUserFlowsByName($appId: ID!, $flowName: String!) {
              searchUserFlowsByName(appId: $appId, flowName: $flowName) {
                        id
                        name
              }
            }` // Added missing closing bracket for the query
    })
  }).then(res => res.json());
})



export { 
  fetchAllUserFlowNamesByAppId, 
  fetchUserFlowByAppId, 
  fetchiOSUserFlowByAppId, 
  fetchUserFlowById, 
  fetchIOSUserFlowById,
  fetchTopAndBottomFlowById,
  searchUserflowByName
}


