// FlowsGridImageRowWebApp;.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {updateSelectedFlow} from '../../store'
import { useNavigate } from 'react-router-dom';




const FlowsGridImageRowWebApp = ({screens, flowId, flow}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

  //console.log(screens)
  //console.log(flowId)

const handleClickButton = (r) => {
    if (flow.isBlurred) {
        navigate('/signin');
    } else {
        dispatch(updateSelectedFlow(flow))
        navigate(`/web/apps/flows/${flow.id}`);
    }
   //dispatch(updateSelectedFlow(flow))
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu from opening
  };


  return (
  //asdas
     <div className={`webapp-flows-horizontal-scrollable-list-container ${flow.isBlurred ? 'blurred' : ''}`}>
    {/* <Link to={"/web/apps/flows/"+flowId}
    onClick={()=> handleClickButton(flowId)}
    > */}
    <div onClick={()=> handleClickButton(flow)} style={{ cursor: 'pointer' }}>
    
   <ul className="web-list">
  
   {
      screens.map((screen, i) => (

        <li key={i} onContextMenu={handleContextMenu}>
                <img src={screen.image.image_url}/>
            </li>
            
          ))
    }
   </ul> 
   </div>
  </div> 
  );
};


export default FlowsGridImageRowWebApp;







