import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const fetchInitialiOSApps = createAsyncThunk('fetchInitialiOSApps/fetch', async(term) =>{
  //console.log("fetchInitialiOSApps")
  //console.log(KEYS.WEBAPPS_PUBLISH_STATUS)

  const token = localStorage.getItem('token');  // Retrieve the authentication token

	return fetch(KEYS.GRAPHQL_URL, {
	//const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { 
      "Content-Type": "application/json",
      'token': token ? `${token}` : '',  // Include the token in the header
     },
    body: JSON.stringify({
      //query: query,
      variables: {
        iOSappsPublishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
    	query: `query ($iOSappsPublishStatus: String) {
                 iosapps(publishStatus: $iOSappsPublishStatus) {
                  __typename
                  name
                  id
                  description
                  logo_url
                  initial_image_url
                  isBlurred
                  categories {
                    id
                    name
                  }
                }
              }`	
    })
  }).then(res => res.json())
    //console.log(response)
})


const fetchiOSAppById = createAsyncThunk('fetchiOSAppById/fetch', async(term) =>{
  //console.log("fetchiOSAppById")
  const token = localStorage.getItem('token');  // Retrieve the authentication token

  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json",
      'token': token ? `${token}` : '',  // Include the token in the header
     },
    body: JSON.stringify({
      variables: {
        appId: term,
        applyBlurr: false
      },
      query: `query AppById($appId: ID!, $applyBlurr: Boolean) {
               appById(appId: $appId, applyBlurr: $applyBlurr) {
                ... on iOS {
                      __typename
                      name
                      id
                      description
                      logo_url
                      initial_image_url
                      isBlurred
                      categories {
                        id
                        name
                      }
                      productInsight {
                                      productText
                                      valueText
                                      shouldDisplay
                                      monetizationText
                                      competitorText
                                      userSegmentText
                                      missionText
                                    } 


                        }
                  }
            }`  
    })
  }).then(res => res.json())
    //console.log(response)
})

const fetchProductInsightForiOSApp = createAsyncThunk('fetchProductInsightForiOSApp/fetch', async(term) =>{
  //console.log("fetchProductInsightForiOSApp")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        appId: term
      },
      query: `query ProductInsightAppById($appId: ID!) {
                                              productInsightAppById(appId: $appId) {
                                              shouldDisplay
                                              productText
                                              userSegmentText
                                              valueText
                                              monetizationText
                                              competitorText
                                              missionText
                                            }
                                          }`  
    })
  }).then(res => res.json())
    console.log(response)
})

const fetchIOSCompetitorsByAppId = createAsyncThunk('fetchIOSCompetitorsByAppId/fetch', async(term) =>{
  //console.log("fetchIOSCompetitorsByAppId")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        appId: term,
        publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
      query: `query findCompetitorsByAppId($appId: ID!, $publishStatus: String) {
      findCompetitorsByAppId(appId: $appId, publishStatus: $publishStatus) {
                      ... on iOS{
                        id
                        name
                        href
                        publishStatus
                      },
                      ... on Web{
                        id
                        name
                        href
                        publishStatus
          }
        }
      }`
    })
  }).then(res => res.json())
    //console.log(response)

})



export { fetchInitialiOSApps, fetchiOSAppById, fetchProductInsightForiOSApp, fetchIOSCompetitorsByAppId}