import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')
import { priceRangeList } from '../../utils/priceRangeList';
import { transactionFeePriceRangeList } from '../../utils/transactionFeesPricingList';


const fetchInitialWebApps = createAsyncThunk('fetchInitialWebApps/fetch', async(term) =>{
  //console.log("fetchInitialWebApps")
  //console.log(KEYS.WEBAPPS_PUBLISH_STATUS)

  const token = localStorage.getItem('token');  // Retrieve the authentication token

	return fetch(KEYS.GRAPHQL_URL, {
	//const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json",
      'token': token ? `${token}` : '',  // Include the token in the header
     },
    body: JSON.stringify({
      //query: query,
      variables: {
        webappsPublishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
    	query: `query ($webappsPublishStatus: String) {
                webapps(publishStatus: $webappsPublishStatus) {
                  __typename
                  name
                  id
                  description
                  logo_url
                  initial_image_url
                  isBlurred
                  categories {
                    id
                    name
                  }
                }
              }`	
    })
  }).then(res => res.json())
    console.log(response)
})


const fetchWebAppById = createAsyncThunk('fetchWebAppById/fetch', async(term) =>{
  //console.log("fetchWebAppById")
  const token = localStorage.getItem('token');  // Retrieve the authentication token

  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json",
      'token': token ? `${token}` : '',  // Include the token in the header
     },
    body: JSON.stringify({
      //query: query,
      //variables: variables
      query: `{
              appById (appId: "${term}") {
                ... on Web {
                      __typename
                      name
                      id
                      description
                      logo_url
                      initial_image_url
                      isBlurred
                      categories {
                        id
                        name
                      }
                      productInsight {
                                      productText
                                      valueText
                                      shouldDisplay
                                      monetizationText
                                      competitorText
                                      userSegmentText
                                      missionText
                                    }  
                        }
                  }
            }`  
    })
  }).then(res => res.json())
    //console.log(response)
})

//As of now just using it to log the app name users are searching from the search bar
//goal is to identify popular apps. 
const fetchWebAppByName = createAsyncThunk('fetchWebAppByName/fetch', async(terms) =>{
   //NOTE: TERMS here is an obj
   //just for this api as it evloves special chars in terms
   const encodedTerm = encodeURIComponent(terms.name);
  //console.log("fetchWebAppByName encodedTerm", encodedTerm) 
  //console.log("fetchWebAppByName type", terms) 
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
      query: `{
              searchAppByName (appName: "${encodedTerm}", appType: "${terms.__typename}") 
                
            }`  
    })
  }).then(res => res.json())
   // console.log(res)
})


const fetchProductInsightForWebApp = createAsyncThunk('fetchProductInsightForWebApp/fetch', async(term) =>{
  //console.log("fetchProductInsightForWebApp")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        appId: term
      },
      query: `query ProductInsightAppById($appId: ID!) {
                                              productInsightAppById(appId: $appId) {
                                              shouldDisplay
                                              productText
                                              userSegmentText
                                              valueText
                                              monetizationText
                                              competitorText
                                              missionText
                                            }
                                          }`  
    })
  }).then(res => res.json())
    console.log(response)
})

const fetchWebCompetitorsByAppId = createAsyncThunk('fetchWebCompetitorsByAppId/fetch', async(term) =>{
  //console.log("fetchWebCompetitorsByAppId")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        appId: term,
        publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
      query: `query findCompetitorsByAppId($appId: ID!, $publishStatus: String) {
      findCompetitorsByAppId(appId: $appId, publishStatus: $publishStatus) {
                      ... on iOS{
                        id
                        name
                        href
                        publishStatus
                      },
                      ... on Web{
                        id
                        name
                        href
                        publishStatus
          }
        }
      }`
    })
  }).then(res => res.json())
    //console.log(response)
})

const fetchAllAppCategories = createAsyncThunk('fetchAllAppCategories/fetch', async() =>{
  //console.log("fetchAllAppCategories")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
      query: `{
              allCategories {
                count
                categories {
                  id
                  name
                  href
                }
              }
            }`  
    })
  }).then(res => res.json())
    console.log(response)
});

const fetchAllAppMonetizations = createAsyncThunk('fetchAllAppMonetizations/fetch', async() =>{
  //console.log("fetchAllAppMonetizations")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
      query: `{
              allMonetizations {
                count
                monetizations {
                  id
                  name
                }
              }
            }`  
    })
  }).then(res => res.json())
    console.log(response)
});

const fetchAllAppPricings = createAsyncThunk('fetchAllAppPricings/fetch', async() =>{
  //console.log("fetchAllAppPricings")

  //retunrn transactionFeePriceRangeList and priceRangeList combined
  return [...transactionFeePriceRangeList, ...priceRangeList]
 
  // return fetch(KEYS.GRAPHQL_URL, {
  //   method: 'POST',
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify({
  //     //query: query,
  //     //variables: variables
  //     query: `{
  //             allMonetizations {
  //               count
  //               monetizations {
  //                 id
  //                 name
  //               }
  //             }
  //           }`  
  //   })
  // }).then(res => res.json())
  //   console.log(response)
});

const filteredApps = createAsyncThunk('filteredApps/fetch', async (_, { getState }) =>{
  //console.log("filteredApps")
  const token = localStorage.getItem('token');  // Retrieve the authentication token

  const { categories, monetizations, priceRanges } = getState().searchfilter
  //console.log("filteredApps categories", categories)
  //console.log("filteredApps monetizations", monetizations)
  //console.log("filteredApps priceRanges", priceRanges)
  //if priceRanges length is greater than 0, then we need to filter out 'name' from priceRanges and pass the
  // array of names to the query
  let priceRange = []
  if(priceRanges.length > 0){
    priceRange = priceRanges.map(priceRange => priceRange.name)
  }
  //console.log("filteredApps priceRange", priceRange)

  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json",
      'token': token ? `${token}` : '',  // Include the token in the header
     },
    body: JSON.stringify({
      //query: query,
      variables: { categories, monetizations, priceRange },
      query: `query FilteredApps($monetizations: [ID]!, $categories: [ID]!, $priceRange: [String]) { 
            filteredApps(monetizations: $monetizations, categories: $categories, priceRange: $priceRange) {
                  ... on Web {
                        __typename
                        name
                        id
                        description
                        logo_url
                        initial_image_url
                        isBlurred
                        categories {
                          id
                          name
                        }
                  }
                  ... on iOS {
                      __typename
                      name
                      id
                      description
                      logo_url
                      initial_image_url
                      isBlurred
                      categories {
                        id
                        name
                      }
                  }
            
              }
            }
      `  
    })
  }).then(res => res.json())
    console.log(response)
});


export { 
  fetchInitialWebApps, 
  fetchWebAppById, 
  fetchWebAppByName, 
  fetchProductInsightForWebApp, 
  fetchWebCompetitorsByAppId,
  fetchAllAppCategories,
  filteredApps,
  fetchAllAppMonetizations,
  fetchAllAppPricings
}
